import api from 'api';

export const getHotelMeals = () => {
    return api({
        method: 'GET',
        url: `hotel/meals/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        }
    });
};

export const getHotelFeaturedMeals = () => {
    return api({
        method: 'GET',
        url: `hotel/fetured/meals/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        }
    });
};

export const getEvents = () => {
    return api({
        method: 'GET',
        url: `getAllEvents/?web=true`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        }
    });
};

export const getFilteredEvents = (category) => {
    return api({
        method: 'GET',
        url: `getAllEvents/?category=${category}&web=true`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        }
    });
};

export const getEventDetails = (event_id) => {
    return api({
        method: 'GET',
        url: `getAllEvents/${event_id}/`,
        headers: {
            'Authorization': `${localStorage.getItem('openbarcity_token')}`
        }
    });
};

export const getInfluenceCommissions = (influence_id) => {
    return api({
        method: 'GET',
        url: `get-commissions/influence/${influence_id}/`
    });
};

export const getBarIssuedVictuals = (bar_id) => {
    return api({
        method: 'GET',
        url: `get-bar-issued-victuals/${bar_id}/`
    });
};

export const getBarSampleDrinks = (bar_id) => {
    return api({
        method: 'GET',
        url: `get-bar-sample-drinks/${bar_id}/`
    });
};

export const sendEmailInfluencePayment = (influence_id) => {
    return api({
        method: 'POST',
        url: `send-email-payment/influence/${influence_id}/`
    });
};

export const checkInfluenceValid = (data) => {
    return api({
        method: 'POST',
        url: `check-influence/`,
        data,
    });
};

export const sendCouponAPI = (data) => {
    return api({
        method: 'POST',
        url: `send-coupon/`,
        data,
    });
};

export const sendBarOrder = (data) => {
    return api({
        method: 'POST',
        url: `send-bar-order-details/`,
        data,
    });
};

export const sendBarSampleOrder = (data) => {
    return api({
        method: 'POST',
        url: `send/bar/sample/order/`,
        data,
    });
};

export const barUploadVideo = (data) => {
    return api({
        method: 'POST',
        url: `bar-upload-video/`,
        data,
    });
};
// free ghost ticket
export const getInfluenceAssignedDrinks = (influence_id) => {
    return api({
        method: 'GET',
        url: `get-influence-assigned-drinks/${influence_id}/`,
    });
};

export const getInfluenceAssignedDrinksnMeals = (influence_id) => {
    return api({
        method: 'GET',
        url: `influence/assigned/drinksnmeals/${influence_id}/`,
    });
};

export const getInfluenceUsedDrinks = (influence_id) => {
    return api({
        method: 'GET',
        url: `get-influence-used-drinks/${influence_id}/`,
    });
};

export const getInfluenceUsedDrinksnMeals = (influence_id) => {
    return api({
        method: 'GET',
        url: `influence/used/drinksnmeals/${influence_id}/`,
    });
};

export const influenceUserAssignDrink = (data) => {
    return api({
        method: 'POST',
        url: `assign-drinks-to-user/`,
        data,
    });
};

export const influenceUserAssignDrinknMeal = (data) => {
    return api({
        method: 'POST',
        url: `user/assign/drinksnmeals/`,
        data,
    });
};

export const hotelCeditOrder = (data) => {
    return api({
        method: 'POST',
        url: `send/hotel/credit/order/`,
        data,
    });
};

// paid ghost ticket
export const getInfluenceAssignedVictuals = (influence_id) => {
    return api({
        method: 'GET',
        url: `get-influence-assigned-victuals/${influence_id}/`,
    });
};

export const getInfluenceUsedVictuals = (influence_id) => {
    return api({
        method: 'GET',
        url: `get-influence-used-victuals/${influence_id}/`,
    });
};

export const influenceUserAssignVictual = (data) => {
    return api({
        method: 'POST',
        url: `assign-victuals-to-user/`,
        data,
    });
};

export const barTranscation = (barId) => {
    return api({
        method: 'GET',
        url: `bar/transcations/${barId}/`,
    });
};