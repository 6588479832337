import {getProfile} from 'api/auth';
import {authNameSpacer} from 'lib/reducers';

export const setHeaders = async (res, history, loginUrl = '/') => {
    localStorage.clear();
    const data = res.data.response;
    if (data) {
        if (data.sessionId) {
            let token = 'Token ' + data.sessionId;
            localStorage.setItem('openbarcity_token', token);
            localStorage.setItem('openbarcity_user_id', data.id);
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000); // 1 hour
            localStorage.setItem('openbarcity_expirationDate', expirationDate);
        }
    }

    // set cookie policy on login/signup
    let d = new Date();
    d.setDate(d.getDate() + 30); // 1 month
    document.cookie = `cookiepolicy=true; expires=${d}; path=/`;
    try {
        const user = await getProfile(data.id);
        localStorage.setItem('openbarcity_user', JSON.stringify(user.data.response));
        if (!loginUrl.includes('purchase')) {
            history.push(loginUrl);
        }
    } catch (error) {
        if (error.response.status === 401) {
            localStorage.clear();
        }
    }
};

export const setInfluenceHeaders = (res, history, loginUrl = '/influence') => {
    const data = res.data;
    if (data.username) {
        localStorage.setItem('openbarcity_influence', data.username);
        localStorage.setItem('openbarcity_influence_id', data.id);
        localStorage.setItem('openbarcity_influence_has_credit_order', data.has_credit_order);
        localStorage.setItem('openbarcity_influence_qr', data.qr);
        history.push(loginUrl);
    }
};

export const setBarHeaders = (res, history, loginUrl = '/bar') => {
    const data = res.data;
    if (data.email) {
        localStorage.setItem('openbarcity_bar', data.email);
        localStorage.setItem('openbarcity_bar_id', data.id);
        localStorage.setItem('openbarcity_bar_qr', data.qr);
        history.push(loginUrl);
    }
};

export const setHotelHeaders = (res, history, url = '/login') => {
    const data = res.data;
    if (data.username) {
        localStorage.setItem('openbarcity_hotel_user', data.username);
        localStorage.setItem('openbarcity_hotel_user_id', data.id);
        history.push(url);
    }
};

export const clearStorage = (dispatch) => {
    localStorage.removeItem('openbarcity_token');
    localStorage.removeItem('openbarcity_user_id');
    localStorage.removeItem('openbarcity_expirationDate');
    localStorage.removeItem('openbarcity_user');
    dispatch({
        type: authNameSpacer('SET_LOGGED_IN'),
        payload: {
            isLoggedIn: false,
            token: null,
        }
    })
};

export const clearInfluenceStorage = (dispatch) => {
    localStorage.removeItem('openbarcity_influence');
    localStorage.removeItem('openbarcity_influence_id');
    dispatch({
        type: authNameSpacer('SET_INFLUENCE_LOGGED_IN'),
        payload: {
            isInfluenceLoggedIn: false,
        }
    })
};

export const clearBarStorage = (dispatch) => {
    localStorage.removeItem('openbarcity_bar');
    localStorage.removeItem('openbarcity_bar_id');
    dispatch({
        type: authNameSpacer('SET_BAR_LOGGED_IN'),
        payload: {
            isBarLoggedIn: false
        }
    })
};

export const clearHotelUserStorage = (dispatch) => {
    localStorage.removeItem('openbarcity_hotel_user');
    localStorage.removeItem('openbarcity_hotel_user_id');
    dispatch({
        type: authNameSpacer('SET_HOTEL_USER_LOGGED_IN'),
        payload: {
            isHotelUserLoggedin: false,
        }
    })
};
