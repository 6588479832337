import React, {useState} from "react";
import {BsPower} from "react-icons/bs";
import {AiOutlineUser} from "react-icons/ai";
import {FaExchangeAlt, FaAward} from "react-icons/fa";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "api/auth";
import {clearStorage, clearInfluenceStorage, clearBarStorage, clearHotelUserStorage} from "lib/utils";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import defaultUserAvatar from '../../../assets/images/default_user_avatar.jpeg';

export default function HeaderAuthorAccess() {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isInfluenceLoggedIn = useSelector((state) => state.auth.isInfluenceLoggedIn);
    const isBarLoggedIn = useSelector((state) => state.auth.isBarLoggedIn);
    const isHotelUserLoggedin = useSelector((state) => state.auth.isHotelUserLoggedin);
    const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem("openbarcity_user"));
    const avatar = user?.thumbnail !== undefined ? user.thumbnail : defaultUserAvatar;
    const full_name = user?.first_name !== undefined ? user?.first_name : localStorage.getItem("openbarcity_influence") + " " + user?.last_name !== undefined ? user?.last_name : "";

    const handleLogout = () => {
        async function handle() {
            try {
                if (isLoggedIn) {
                    await logout();
                    clearStorage(dispatch);
                } else if (isInfluenceLoggedIn) {
                    clearInfluenceStorage(dispatch);
                } else if (isBarLoggedIn) {
                    clearBarStorage(dispatch);
                } else if (isHotelUserLoggedin) {
                    clearHotelUserStorage(dispatch);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    clearStorage(dispatch);
                }
            } finally {
                history.push("/");
            }
        }

        handle();
    };

    const handleSideUserMenuOpen = () => {
        setAuthorAccessOpen(!AuthorAccessOpen);
    };

    return (
        <>
            <div className="logo-right-content">
                <ul className="author-access-list">
                    {!isLoggedIn && !isInfluenceLoggedIn && !isBarLoggedIn && !isHotelUserLoggedin && !(window.location.href.indexOf("purchase") > -1) && (
                        <li>
                            <Link to="/login" className="author-access-list__login">
                                LOGIN
                            </Link>
                            {/* <span className="or-text">OR</span> */}
                            <Link to="/sign-up" className="author-access-list__signup">
                                SIGN UP
                            </Link>
                        </li>
                    )}
                    {!(window.location.href.indexOf("purchase") > -1) && isLoggedIn && (
                        <li>
                            {/*<Button text="Events" url="/buy-now">*/}
                            {/*  <FaCocktail />*/}
                            {/*</Button>*/}
                        </li>
                    )}
                </ul>
                {(isLoggedIn || isInfluenceLoggedIn || isBarLoggedIn) && (
                    <div
                        className="side-user-menu-open"
                        onClick={() => handleSideUserMenuOpen()}
                    >
                        <AiOutlineUser/>
                    </div>
                )}
            </div>

            {/* Side User panel */}
            <div
                className={
                    AuthorAccessOpen ? "side-user-panel active" : "side-user-panel"
                }
            >
                <div className="humburger-menu">
                    <div
                        className="humburger-menu-lines side-menu-close"
                        onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}
                    ></div>
                </div>
                <div className="side-menu-wrap side-user-menu-wrap">
                    <div className="side-user-img">
                        <img src={avatar} alt="User"/>
                        <h4 className="su__name">{full_name}</h4>
                    </div>

                    <ul className="side-menu-ul">
                        {/* General User */}

                        {isLoggedIn && (
                            <>
                                <li>
                                    <Link to="/profile">
                                        <AiOutlineUser className="user-icon"/> Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/user/events">
                                        <PaymentOutlinedIcon className="user-icon"/> Events
                                    </Link>
                                </li>
                            </>
                        )}

                        {/* Influencer */}
                        {isInfluenceLoggedIn && (
                            <>
                                <li>
                                    <Link to="/influence">
                                        <AiOutlineUser className="user-icon"/> Profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/rewards">
                                        <FaAward className="user-icon"/> Reward
                                    </Link>
                                </li>
                            </>
                        )}
                        {/* Bar User */}
                        {isBarLoggedIn && (
                            <>
                                <li>
                                    <Link to="/bar">
                                        <AiOutlineUser className="user-icon"/> Profile
                                    </Link>
                                </li>
                                <li className="d-none">
                                    <Link to="/bar/transactions">
                                        <FaExchangeAlt className="user-icon"/> Transactions
                                    </Link>
                                </li>
                            </>
                        )}
                        {/* Divider */}
                        <li>
                            <div className="dropdown-divider"></div>
                        </li>
                        {/* Sign Out */}
                        <li>
                            <Link to="#" onClick={() => handleLogout()}>
                                <BsPower className="user-icon"/> Sign out
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
